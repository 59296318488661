import Flickity from 'vue-flickity'
import CourseCard from '@/components/CourseCard/index.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CourseCarousalPaginated',
  props: {
    feedName: {
      type: String
    },
    mainHeading: {
      type: String
    },
    feedHeading: {
      type: String,
      required: true
    },
    cardCss: {
      type: Object,
      required: true
    }
  },
  components: { Flickity, CourseCard },
  data () {
    return {
      flickityOptions: {
        groupCells: 4,
        initialIndex: this.currentActiveIndex,
        prevNextButtons: true,
        pageDots: false,
        contain: true,
        lazyLoad: 2,
        draggable: false,
        cellAlign: 'left'
        // any options from Flickity can be used
      },
      currentActiveIndex: 0,
      coursesArray: [],
      isFeedLoading: false

    }
  },
  created () {
    console.log('in new Component')
    this.getCurrentViewportWidth()
    console.log('Feed Name', this.feedName)
    if (this.feedName === 'POPULAR') {
      this.GET_MOST_POPULAR({
        params: {
          'sort': 'POPULARITY',
          'page': 0
        },
        append: false,
        success: () => {
          this.coursesArray = this.getMostPopular
        }
      })
    }
    if (this.feedName === 'LIKED') {
      this.GET_MOST_LIKED({
        params: {
          'sort': 'MOST_LIKED',
          'page': 0
        },
        append: false,
        success: () => {
          this.coursesArray = this.getMostLiked
        }
      })
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions([
      'GET_MOST_POPULAR',
      'SET_FEED_LOADING',
      'GET_MOST_LIKED'
    ]),
    showPrevElement () {
      this.$refs.flickity.previous()
    },
    showNextElement () {
      this.$refs.flickity.next()
    },
    getCurrentViewportWidth () {
      let device
      if (window.innerWidth < 550) {
        this.flickityOptions.groupCells = 1
        device = 'MOBILE'
      }

      if (window.innerWidth > 550 && window.innerWidth <= 1024) {
        this.flickityOptions.groupCells = 3
        device = 'WEB'
      }
      return device
    },
    onInit () {
      this.$refs.flickity.on('change', index => {
        this.currentActiveIndex = index
      })
      this.flickityOptions.initialIndex = this.currentActiveIndex
    }
  },
  computed: {
    ...mapGetters([
      'getFeedLoading',
      'getMostLiked',
      'getMostPopular',
      'getMostLikedResponse',
      'getMostPopularResponse'
    ]),
    cssProps () {
      return {
        '--feedHeading-color': this.cardCss.feedColor,
        '--mainHeading-color': this.cardCss.mainColor
      }
    },
    courseList () {
      return this.coursesArray
    },
    loadingStatus () {
      return this.getFeedLoading
    }
  },
  watch: {
    currentActiveIndex () {
      console.log('Current Index is ' + this.currentActiveIndex)
      if (this.flickityOptions.groupCells > 1) {
        console.log('In web view' + (this.flickityOptions.groupCells * (this.currentActiveIndex + 1)))
        if ((this.flickityOptions.groupCells * (this.currentActiveIndex + 1)) >= this.coursesArray.length) {
          console.log('About to call next Page')
          if (this.feedHeading === 'Most Popular') {
            console.log('Total Page Numbers', this.getMostPopularResponse.totalPages)
            console.log('Current Page Numbers', this.getMostPopularResponse.pageNumber)
            if (this.getMostPopularResponse.totalPages > this.getMostPopularResponse.pageNumber + 1) {
              this.isFeedLoading = true
              console.log('Calling next Page')
              this.GET_MOST_POPULAR({
                params: {
                  'sort': 'POPULARITY',
                  'page': this.getMostPopularResponse.pageNumber + 1
                },
                append: true,
                success: () => {
                  this.coursesArray = this.getMostPopular
                  this.isFeedLoading = false
                }
              })
            }
          }
        } else if (this.feedHeading === 'Most Liked') {
          console.log('In most Liked feed')
          if (this.getMostLikedResponse.totalPages > this.getMostLikedResponse.pageNumber + 1) {
            console.log('Calling next Page')
            this.isFeedLoading = true
            this.GET_MOST_LIKED({
              params: {
                'sort': 'MOST_LIKED',
                'page': this.getMostLikedResponse.pageNumber + 1
              },
              append: true,
              success: () => {
                this.coursesArray = this.getMostLiked
                this.isFeedLoading = false
              }
            })
          }
        }
      } else {
        if ((this.currentActiveIndex + 3) > this.coursesArray.length) {
          if (this.feedHeading === 'Most Popular') {
            this.GET_MOST_POPULAR({
              params: {
                'sort': 'POPULARITY',
                'page': this.getMostPopularResponse.pageNumber + 1
              },
              append: true,
              success: () => {
                this.coursesArray = this.getMostPopular
                this.isFeedLoading = false
              }
            })
          } else if (this.feedHeading === 'Most Liked') {
            this.GET_MOST_LIKED({
              params: {
                'sort': 'MOST_LIKED',
                'page': this.getMostLikedResponse.pageNumber + 1
              },
              append: true,
              success: () => {
                this.coursesArray = this.getMostLiked
                this.isFeedLoading = false
              }
            })
          }
        }
      }
    }

  }
}
