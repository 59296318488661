import CourseCard from '@/components/CourseCard/index.vue'
import CourseCarousal from '@/components/CourseCarousal/index.vue'
import CircularSubmenu from '@/components/CircularSubmenu/CircularSubmenu.vue'
import CourseCarousalPaginated from '@/components/CourseCarousalPaginated/index'
import VideoPlayer from '@/components/socialvue/videoplayer/VideoPlayer.vue'
import Loader from '@/components/socialvue/breadcrumbs/Loader.vue'
import Chat from '@/views/Apps/Chat/Index'
import Flickity from 'vue-flickity'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import firebase from 'firebase'

export default {
  name: 'SocialApp',
  components: { CourseCard, Flickity, CourseCarousal, CircularSubmenu, VideoPlayer, Loader, Chat, CourseCarousalPaginated },
  data () {
    return {
      courseArray: [],
      feedPopular: 'POPULAR',
      feedLiked: 'LIKED',
      palylist: '\'s Playlist',
      banner_url: '',
      playListHeading: '',
      feed_influencer_id: 0,
      currentActiveIndex: 0,
      cardColorBlack: {
        'bgColor': 'black',
        'fontColor': 'white',
        'mainColor': 'black',
        'feedColor': 'black'
      },
      cardColorBlackRed: {
        'bgColor': 'black',
        'fontColor': 'white',
        'mainColor': 'red',
        'feedColor': 'black'
      },
      cardColorWhite: {
        'bgColor': 'white',
        'fontColor': 'black',
        'mainColor': 'red',
        'feedColor': 'white'
      },
      manager_feed: {
        feed_heading: 'Operations Manager',
        main_heading: 'REQUIRED SKILLS FOR'
      },
      curated_feed: {
        feed_heading: 'Curated Specially For You',
        main_heading: 'ZOOMIFEED'
      },
      promoted_feed: {
        feed_heading: 'Promoted Content',
        main_heading: 'SPONSERED'
      },
      colleagues_feed: {
        feed_heading: 'Most Liked',
        main_heading: 'DATA DRIVEN'
      },
      popular_feed: {
        feed_heading: 'Most Popular',
        main_heading: 'DATA DRIVEN'
      },
      social_engine_feed: {
        feed_heading: 'Jim Walker\'s Playlist',
        main_heading: 'SOCIAL ENGINE'
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getPromotedFeed1',
      'getPromotedFeed2',
      'getIsAuthenticated',
      'getLoginStatus',
      'getInfluencerPlaylist',
      'getContent',
      'getNewUser',
      'getAnalyticsState',
      'getOpenMessageModal',
      'getMostLiked',
      'getMostPopular'
    ]),
    checkCourseLength () {
      // console.log('Array length ' + this.getContent.contents.length)
      return !!this.courseArray.length
    }

  },
  created () {
    const remoteConfig = firebase.remoteConfig()
    remoteConfig.fetchAndActivate()
      .then(() => {
        const val = remoteConfig.getValue('org_1_banner')
        this.banner_url = JSON.parse(val._value).url
      })
      .catch(() => {
      })
    this.GET_HOME_CONTENT({
      payload: [],
      params: {
        'strategy': 'CONTENT_BASED'
      }
    })
    this.RESET_STATE()
    remoteConfig.fetchAndActivate()
      .then(() => {
        const val = remoteConfig.getValue('home_page_playlist_influencer_id')
        this.feed_influencer_id = JSON.parse(val._value)
        this.GET_INFLUENCER_PLAYLIST({
          influencerId: this.feed_influencer_id,
          success: () => {
            this.playListHeading = this.getInfluencerPlaylist.influencerHeading.split(' ').slice(2).join(' ') + this.palylist
          }
        })
      })
      .catch(() => {
      })
    remoteConfig.fetchAndActivate()
      .then(() => {
        const val = remoteConfig.getValue('promoted_feed_1')
        const promo1 = JSON.parse(val._value)
        this.GET_PROMOTED_FEED_1({
          payload: promo1,
          params: {
            'strategy': 'PROMOTED'
          }
        })
      })
      .catch(() => {
      })
    remoteConfig.fetchAndActivate()
      .then(() => {
        const val = remoteConfig.getValue('promoted_feed_2')
        const promo2 = JSON.parse(val._value)
        this.GET_PROMOTED_FEED_2({
          payload: promo2,
          params: {
            'strategy': 'PROMOTED'
          }
        })
      })
      .catch(() => {
      })
    this.setLoggedUser(this.getUser.userId)
    console.log('On HOME_PAGE')
    this.setPage('HOME_PAGE')
    if (this.getNewUser) {
      console.log('New User CSC')
      this.setNewUser(true)
    }
    // TODO Wrap this in try catch
    // eslint-disable-next-line no-undef
    divolte.signal('pageView', this.getAnalyticsState)
  },
  methods: {
    ...mapActions([
      'GET_HOME_CONTENT',
      'RESET_STATE',
      'GET_PROMOTED_FEED_1',
      'GET_PROMOTED_FEED_2',
      'GET_INFLUENCER_PLAYLIST',
      'GET_MOST_POPULAR',
      'GET_MOST_LIKED'
    ]
    ),
    ...mapMutations([
      'setNewUser',
      'setLoggedUser',
      'setPage'
    ])
  }
}
